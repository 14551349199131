import { useSettings, useStyles } from '@wix/tpa-settings/react';
import settingsParams from '../settingsParams';
import { VisibilityKey } from './types';

export function useVisibilityCheck() {
  const settings = useSettings();
  const styles = useStyles();

  return {
    shouldBeVisible(key: VisibilityKey) {
      const settingsValue = settings.get(settingsParams[key]);
      return typeof settingsValue === 'boolean'
        ? settingsValue
        : styles
            .getStylesForAllBreakpoints()
            .some(({ booleans }) => booleans[key]);
    },
  };
}
